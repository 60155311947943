import AppDispatcher from '../dispatcher/AppDispatcher.js'
import AppConstants from '../constants/AppConstants.js'

const AppActions = {
  addItem(item) {
    AppDispatcher.dispatch({
      actionType: AppConstants.ADD_ITEM,
      item: item
    });
  },

  deleteItemAt(index) {
    AppDispatcher.dispatch({
      actionType: AppConstants.DELETE_ITEM,
      index: index
    });
  },

  editItemAt(index, item) {
    AppDispatcher.dispatch({
      actionType: AppConstants.EDIT_ITEM,
      index: index,
      item: item
    });
  },

  setProductWeight(productWeight) {
    AppDispatcher.dispatch({
      actionType: AppConstants.SET_PRODUCT_WEIGHT,
      productWeight: productWeight
    });
  }
}

export default AppActions
