import React, { Component } from 'react'
import InputForm from './InputForm.js'
import MainList from './MainList.js'
import AppStore from '../stores/AppStore.js'
import AppActions from '../actions/AppActions.js'
// const { version } = require('../../package.json')

class App extends Component {

  constructor(props) {
    super(props)
    this.state = {items: [], productElements: 'None'}

    this.handleChange = this.handleChange.bind(this)
  }

  handleSubmit(item) {
    AppActions.addItem(item)
  }

  handleDestroy(index){
    AppActions.deleteItemAt(index)
  }

  handleUpdate(index, item){
    AppActions.editItemAt(index, item)
  }

  componentWillMount() {
    AppStore.addChangeListener(this.handleChange)
  }

  handleChange() {
    this.setState({items: AppStore.getList(), productElements: AppStore.productElements()})
  }

  render() {
    return (
    <div>
      <header>
        <h1>Stoichio <span className='app-version'>v2.0.0</span></h1>
        <h3>A nice stoichiometric calculator</h3>
      </header>
      <InputForm handleSubmit={this.handleSubmit}/>
      <hr />
      <MainList reactants={this.state.items} productElements={this.state.productElements} />
    </div>
    )
  }
}

export default App
