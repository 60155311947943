import React, { Component } from 'react'

const _ = require('lodash')
const ENTER_KEY = 13

class InputForm extends Component {

  constructor(props) {
    super(props)
    this.state = { ratio: "1", formula: '' }

    this.handleChange = this.handleChange.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.handleKeyUp = this.handleKeyUp.bind(this)
  }

  handleChange(event) {
    let newState = this.state
    newState[event.target.id] = event.target.value
    this.setState(newState)
  }

  handleClick() {
    if ( !this._validate() ) { console.log('invalid input.'); return }

    this.props.handleSubmit(this.state)
    this.setState({ratio: "1", formula: ''})
  }

  handleKeyUp (e) {
    if (e.which === ENTER_KEY) {
      this.handleClick()
    }
  }

  render() {
    return (
       <form>
        <div className="form-group">
          <label htmlFor="ratio">Ratio</label>
          <input
            type="text"
            id="ratio"
            tabIndex='1'
            className="form-control"
            value={this.state.ratio}
            onChange={this.handleChange} />
        </div>
        <div className="form-group">
          <label htmlFor="formula">Formula</label>
          <input
            type="text"
            id="formula"
            tabIndex='2'
            placeholder='example: NaOH'
            className="form-control"
            value={this.state.formula}
            onChange={this.handleChange}
            onKeyUp={this.handleKeyUp} />
        </div>
        <button type="button" className="btn btn-success" onClick={this.handleClick}>
          <span className="glyphicon glyphicon-plus" /> Add
        </button>
      </form>
    )
  }

  _validate() {
    return !this.state.ratio.match(/[A-Za-z(){}]/) && _.isFinite(eval(this.state.ratio)) && this.state.formula
  }
}

export default InputForm
