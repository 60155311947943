import _ from 'lodash'
import $ from 'jquery'
import { Decimal } from 'decimal.js'

import React, { Component } from 'react'
import AppActions from '../actions/AppActions.js'

const ENTER_KEY = 13

class ReactantItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ratio: this.props.reactant.ratio,
      formula: this.props.reactant.formula,
      isEditing: false
    }

    this.handleDoubleClick = this.handleDoubleClick.bind(this)
    this.handleKeyUp = this.handleKeyUp.bind(this)
    this.handleDestroy = this.handleDestroy.bind(this)
    this.handleUpdate = this.handleUpdate.bind(this)
    this.handleRatioChange = this.handleRatioChange.bind(this)
    this.handleFormulaChange = this.handleFormulaChange.bind(this)
  }

  handleDoubleClick() {
    this.setState({isEditing: true})
  }

  handleKeyUp(e) {
    if (e.which === ENTER_KEY) {
      this.handleUpdate()
    }
  }

  handleDestroy(e) {
    AppActions.deleteItemAt(this.props.index)
  }

  handleUpdate(e) {
    AppActions.editItemAt(this.props.index, this.state)
    this.setState({...this.state, isEditing: false})
  }

  handleRatioChange(e) {
    this.setState({...this.state, ratio: e.target.value})
  }

  handleFormulaChange(e) {
    this.setState({...this.state, formula: e.target.value})
  }

  renderReactantWeight(reactant) {
    return reactant.weight.toDP(4, Decimal.ROUND_HALF_CEIL).toString()
  }

  renderReactantView() {
    return <tr key={this.props.index} onDoubleClick={this.handleDoubleClick}>
      <td data-title="Ratio">
        <button className='destroy' onClick={this.handleDestroy}></button>
        {this.props.reactant.ratio}
      </td>
      <td data-title="Formula">
        {this.props.reactant.formula}
      </td>
      <td data-title="MM">
        {this.props.reactant.mm.toString()}
      </td>
      <td data-title="Weight">
        {this.props.reactant.weight ? this.renderReactantWeight(this.props.reactant) : '?'}
      </td>
    </tr>
  }

  renderReactantInput() {
    return <tr key={this.props.index}>
      <td data-title="Ratio">
        <input type="text"
          value={this.state.ratio}
          onChange={this.handleRatioChange}
          onKeyUp={this.handleKeyUp} />
      </td>
      <td data-title="Formula">
        <input type="text"
          value={this.state.formula}
          onChange={this.handleFormulaChange}
          onKeyUp={this.handleKeyUp} />
      </td>
      <td colSpan='2'>
        <button type='button' className='btn btn-primary btn-sm' onClick={this.handleUpdate}>
          <span className="glyphicon glyphicon-save"></span> Save
        </button>
      </td>
    </tr>
  }

  render() {
    return this.state.isEditing ? this.renderReactantInput() : this.renderReactantView()
  }
}

export default ReactantItem
