import { v4 } from 'uuid'
import React, { Component } from 'react'
import ReactantItem from './ReactantItem.js'
import AppActions from '../actions/AppActions.js'

const ENTER_KEY = 13

class MainList extends Component {

  constructor(props) {
    super(props)
    this.state = { productWeight: 0 }

    this.handleBlur = this.handleBlur.bind(this)
    this.handleKeyUp = this.handleKeyUp.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  reactants() {
    if(!this.props.reactants) { return [] }

    return this.props.reactants.map((reactant, index) => {
      return <ReactantItem key={ v4() } index={index} reactant={reactant} />
    })
  }

  handleBlur() {
    AppActions.setProductWeight(this.state.productWeight)
  }

  handleKeyUp(e) {
    if (e.which === ENTER_KEY) {
      AppActions.setProductWeight(this.state.productWeight)
    }
  }

  handleClick() {
    AppActions.setProductWeight(this.state.productWeight)
  }

  handleChange(e) {
    this.setState({productWeight: e.target.value})
  }

  render() {
    return <div>
      <div className="top-buffer"></div>
      <div id="table-responsive">
        <table className="table table-striped table-condensed reactants-table">
            <thead>
            <tr>
                <th className='col-md-2'>Ratio</th>
                <th className='col-md-3'>reactant</th>
                <th className='col-md-3'>Molecular Weight</th>
                <th className='col-md-3'>Weight</th>
            </tr>
            </thead>
            <tbody id="reactants">
              { this.reactants() }
            </tbody>
            <tfoot>
            <tr>
                <th colSpan='2' id='elements'>
                <strong>Elements => </strong>{this.props.productElements}
                </th>
                <th className='col-md-5' colSpan='2'>
                <div className='input-group'>
                  <div className='input-group-prepend'>
                    <span className="input-group-text">Product Weight</span>
                  </div>
                  <input type='number' className='form-control'
                    onBlur={this.handleBlur} onKeyUp={this.handleKeyUp} onChange={this.handleChange} />
                  <div className='input-group-append'>
                    <span className="input-group-text">⏎</span>
                  </div>
                </div>
                </th>
            </tr>
            <tr className='mobile col-offset-3 col-md-6 col-sm-6'>
                <th>
                <button className='btn btn-success'>Calculate</button>
                </th>
            </tr>
            </tfoot>
        </table>
      </div>
    </div>
  }

}

export default MainList
