import _ from 'lodash'
import { Decimal } from 'decimal.js'

import AppConstants from '../constants/AppConstants.js'
import AppDispatcher from '../dispatcher/AppDispatcher.js'
import { EventEmitter } from 'events'

const { parse } = require('molecular-parser');
const AtomicMass = require('atomic-mass');

const SIGNIFICANT_DIGIT = 4;
const CHANGE_EVENT = 'CHANGE';

let _itemList = [];
let _productWeight = null;

const AppStore = Object.assign(new EventEmitter(), {
  getList() {
    if (_productWeight === 0) {
      this._deleteActualWeight();
    } else {
      this.calcActualWeight();
    }
    return _itemList;
  },

  addItem(item) {
    _itemList.push(this._formatReactant(item));
  },

  deleteItemAt(index) {
    _itemList.splice(index, 1);
  },

  editItemAt(index, item) {
    _itemList[index] = this._formatReactant(item);
  },

  productElements() {
    if(_itemList.length === 0 ) { return; };
    let result =  _.reduce(_itemList, (sum, item) => { return this._elementMerge(sum, item); }, {});
    return JSON.stringify(result).replace(/[{}"']/g, '').replace(/(,|:)/g, '$1 ');
  },

  setProductWeight(productWeight) {
    _productWeight = productWeight || 0;
  },

  calcActualWeight() {
    if (_itemList.length <= 0 || !_productWeight ) {
      return;
    }

    let totalMolecularWeight = this._totalMolecularWeight()
    let weightFactor = Decimal.div(_productWeight, totalMolecularWeight)

    _.each(_itemList, (item) => {
      item['weight'] = Decimal.mul(Decimal.mul(item.mm, item.ratio), weightFactor)
    });
  },

  emitChange() {
    this.emit(CHANGE_EVENT);
  },

  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  },

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  },

  _elementMerge(item1, item2) {
    let clone1 = _.clone(item1);
    let elements2 = this._weightingElements(item2);
    return _.mergeWith(clone1, elements2, (a, b) => { if (a) { return Decimal.add(a, b) } });
  },

  _weightingElements(item) {
    return _.reduce(item.elements, (accu, value, key) => {
      accu[key] = Decimal.mul(value, item.ratio)
      return accu
    }, {})
  },

  _formatReactant(reactant) {
    let elements = parse(reactant.formula);
    let atomic_mass = _.map(elements, (value, key) => { return Decimal.mul(AtomicMass[key], value) })
    let mm = _.reduce(atomic_mass, (sum, value) => { return Decimal.add(sum, value) }, 0);

    return {...reactant, elements, mm};
  },

  _totalMolecularWeight() {
    return _.reduce(_itemList, (result, item) => {
      return Decimal.add(result, Decimal.mul(item.ratio, item.mm))
    }, 0);
  },

  _deleteActualWeight() {
    _.each(_itemList, (item) => {
      delete item['weight'];
    });
    return;
  }
});

AppDispatcher.register( function(payload) {

  switch(payload.actionType) {
    case AppConstants.ADD_ITEM:
      AppStore.addItem(payload.item);
      break;
    case AppConstants.DELETE_ITEM:
      AppStore.deleteItemAt(payload.index);
      break;
    case AppConstants.EDIT_ITEM:
      AppStore.editItemAt(payload.index, payload.item);
      break;
    case AppConstants.SET_PRODUCT_WEIGHT:
      AppStore.setProductWeight(Number.parseFloat(payload.productWeight));
      break;
  }

  AppStore.emitChange();
  return true;
})

export default AppStore;
